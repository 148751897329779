<template>
    <div class="Carveup">
        <div class="Carveup-head">
            <div class="Carveup-head-time">
                <span>2022年12月25日-2023年1月3日</span>
            </div>
            <div class="Carveup-head-logo">
                <img :src="bigicon" alt="">
                <div>
                    <p>{{name}}</p>
                    <!-- 第{{issue}}期奖池 -->
                    <!-- <p>第\期奖池</p> -->
                    <!-- {{drawTime}}开奖 -->
                    <!-- <p>\开奖</p> -->
                </div>
            </div>
            <div class="Totalfund">
                <p><img src="https://ppyos.xijiuyou.com/202212/money-icon.png" alt=""><span>我的总奖金：</span> <span>{{myAward}}元</span></p>
                <p><img src="https://ppyos.xijiuyou.com/202212/start-icon.png" alt=""><span>我的奖券：</span> <span>{{myTickets}}张</span></p>
            </div>
        </div>
        <div class="Strategy">
            <div class="Strategy-head">
                <img src="https://ppyos.xijiuyou.com/202212/carve-gl.png" alt="">
                <div>
                    <div>1</div>
                    <p>玩游戏</p>
                </div>
                <div>
                    <div>2</div>
                    <p>拿奖券</p>
                </div>
                <div>
                    <div>3</div>
                    <p>明日瓜分奖池现金</p>
                </div>
            </div>
            <div class="Strategy-content">
                <div class="Strategy-content-head">
                    <!-- <p>上期记录</p> -->
                    <!-- <p>{{issue}}奖池</p> -->
                    <!-- <p></p> -->
                </div>
                <!-- <div class="Strategy-content-money"><span :data-text="totalMoney">{{totalMoney}}</span>元</div> -->
                <div class="Strategy-content-money"><span :data-text="totalMoney">活动已结束</span></div>
                
                <div class="Strategy-content-quan">
                    <div>
                        <p>单张奖券最高价值</p>
                        <p><span>{{showMaxOneTicketMoney}}</span> <span>元</span> </p>
                    </div>
                    <div>
                        <p>当前总奖券<img src="https://ppyos.xijiuyou.com/202212/start-icon.png" alt=""></p>
                        <p><span>{{totalTickets}}</span><span>张</span> </p>
                    </div>
                </div>
                <img class="draw-btn"  @click="showoffer" src="https://ppyos.xijiuyou.com/202212/carve-h5-invite-draw-btn.gif" alt="">
                <img class="irecords"  @click="show=true" src="https://ppyos.xijiuyou.com/202212/carve-h5-invite-records.png" alt="">
            </div>
        </div>

        <div class="Ranking">
            <div class="Ranking-title">我的游戏分数：{{myScore}}</div>
            <div class="progress">
                <div v-for="(item,index) in processes" :key="index"  :style="myScore>=item.needScore?'background: #FFFFFF;z-index:10;':''">
                    <p>{{item.needScore}}</p>
                    <p>+{{item.tickets}}张<img src="https://ppyos.xijiuyou.com/202212/start-rp-icon.png" alt=""></p>
                </div>
                <div v-if="(processes[2] && processes[1] && processes[0])" class="jindu" :style="'width:'+(myScore>processes[2].needScore?100:myScore==processes[2].needScore?85:myScore>=processes[1].needScore?65:myScore>=processes[0].needScore?30:myScore==0?0:myScore<processes[0].needScore?5:0)+'%;'"></div>
            </div>
            <div class="Ranking-titles">玩“{{name}}”，即同时进入每日PK</div>
            <div class="listmy" style="background:rgba(176, 176, 176, 0.1);" v-if="myRank.money">
                <div><span>{{myRank.ranking}}</span><img :src="myRank.headImg?myRank.headImg:'https://ppyos.xijiuyou.com/202206/default_avatar.png'" alt=""><span>我:{{myRank.userid}}</span></div>
                <div>{{myRank.score}}</div>
                <div>+{{myRank.money}}元</div>
            </div>
            <div class="listmy" v-for="(item,index) in list" :key="index">
                <div>
                    <span v-if="index>2" :style="item.ranking==1?'background: #FFC700;font-size: 13px;font-family: AaHouDiHei;color: #884D00;':item.ranking==2?'background: rgba(181, 216, 250, 1);font-size: 13px;font-family: AaHouDiHei;color: #884D00;':item.ranking==3?'background: rgba(247, 156, 119, 1);font-size: 13px;font-family: AaHouDiHei;color: #884D00;':''">{{item.ranking}}</span>
                    <img v-else-if="index==0" src="https://ppyos.xijiuyou.com/202212/sgame-rank-1.png" alt=""/>
                    <img v-else-if="index==1" src="https://ppyos.xijiuyou.com/202212/sgame-rank-2.png" alt=""/>
                    <img v-else-if="index==2" src="https://ppyos.xijiuyou.com/202212/sgame-rank-3.png" alt=""/>
                    <img :src="item.headImg?item.headImg:'https://ppyos.xijiuyou.com/202206/default_avatar.png'" alt=""/>
                    <span>{{item.nickName?item.nickName:'ID:'+item.userid}}</span>
                </div>
                <div>{{item.score}}</div>
                <div>+{{item.money}}元</div>
            </div>
            <!-- <div class="listmy">
                <div><span style="background: rgba(181, 216, 250, 1);font-size: 13px;font-family: AaHouDiHei;color: rgba(69, 86, 109, 1);">2</span><img src="" alt=""><span>我:123456</span></div>
                <div>1545</div>
                <div>+0.1元</div>
            </div>
            <div class="listmy">
                <div><span style="background: rgba(247, 156, 119, 1);font-size: 13px;font-family: AaHouDiHei;color: rgba(126, 66, 43, 1);">3</span><img src="" alt=""><span>我:123456</span></div>
                <div>1545</div>
                <div>+0.1元</div>
            </div> -->
            <div class="btnpk" @click="btnpk">去PK榜再拿奖励 <img src="https://ppyos.xijiuyou.com/202212/icon-right.png" alt=""></div>
        </div>
        <div class="quanbtn">
            <div @click="gogame">玩游戏赢奖券</div>
        </div>
        <div class="jilu" @click="show=true"> 获奖记录</div>
        <div class="guize" @click="closeguize">游戏规则</div>
        <xwpopup  :isshowpopup="guize" @close="closeguize()" :hiddenClose="false">
            <div class="guizes">
                <div>规则</div>
                <p><span>1.</span>小游戏瓜分赛新老用户都可以参加，不限参与次数；</p>
                <p><span>2.</span>每场瓜分赛每日00:00开始，23:59截止，达到对应分值即可获得奖券；</p>
                <p><span>3.</span>所有获得奖券用户都可以参与奖池瓜分，奖券越多，瓜分奖金越高；</p>
                <p><span>4.</span>开奖时间次日9点，开奖后用户可领取奖励，奖励直接到账；</p>
                <p><span>5.</span>成功参与游戏还可进入该小游戏每日PK赛榜单，前100名即额外再拿奖励；</p>
                <p><span>6.</span>活动时间：2022年12月25日-2023年1月3日</p>
                <p><span>7.</span>奖励以等额彩贝形式发放（1元=10万彩贝）</p>
                <div class="gongl">游戏高分攻略</div>
                <p><span>1.</span>玩游戏时可以多使用免费道具</p>
                <p><span>2.</span>游戏失败时点击免费复活可继续玩</p>
                <p><span>3.</span>游戏中途不要提前退出，以免分数不能提交</p>
            </div>
        </xwpopup>
        <van-action-sheet v-model="show" title="获奖记录">
            <div class="showcontent">
                <div class="showcontent-title">奖励以等额彩贝形式发放</div>
                <div class="showcontentlist" v-for="(item,index) in huojlist" :key="index">
                    <div class="showcontentlisttitle">期号：{{item.sdate}}</div>
                    <div class="showcontentlistcontent">
                        <div>
                            <p>总奖券数</p>
                            <p>{{item.totalTickets}} <span>张</span></p>
                        </div>
                        <div>
                            <p>我的奖券</p>
                            <p>{{item.myTickets}} <span>张</span></p>
                        </div>
                        <div>
                            <p>我的奖励</p>
                            <p v-if="(item.status== 2)" class="yuan">{{item.myMoney}}<span>元</span></p>
                            <p v-if="(item.status== -1)" class="cany">未参与</p>
                            <p v-if="(item.status== 0)" class="weikai">未开奖</p>
                            <p v-if="(item.status== 1)" class="lingq" @click="carvedraw(sgid,item)">领取奖励</p>
                        </div>
                    </div>
                </div>
                <div class="wulist" v-if="huojlist.length <= 0">
                    <img src="https://ppyos.xijiuyou.com/202212/ppy-box-img.png" alt="">
                    <p>还没有获奖记录哦，快去玩吧</p>
                </div>
            </div>
        </van-action-sheet>
        <xwpopup  :isshowpopup="succ" @close="closesucc()" :hiddenClose="false">
            <div class="succ">
                <div class="title">
                    <p>{{myTicketsq}}<span>张奖券</span></p>
                    <p>{{drawTime}}开奖</p>
                </div>
                <div class="title-content">还可继续参加每日PK赛拿额外奖励</div>
                <div class="title-btn" @click="closesucc">好的</div>
            </div>
        </xwpopup>
        <xwpopup  :isshowpopup="succnewtime" @close="closesuccnewtime()" :hiddenClose="false">
            <div class="succnewtime">
                <img src="https://ppyos.xijiuyou.com/202212/ppy-fish-img.png" alt="">
                <p v-if="newtime > 1672761599">本活动已结束，感谢关注 <br/> 可以继续参与每日PK擂台赛</p>
                <p v-else-if="newtime < 1671897599">本活动 <span>12月25日</span> 正式开始，敬请期待 <br/> 可以玩玩游戏先练练手</p>
                <div @click="closesuccnewtime">好的</div>
            </div>
        </xwpopup>

        <xwpopup  :isshowpopup="succnmoney" @close="closesuccnmoney()" :hiddenClose="false">
            <div class="succnmoney">
                <img src="https://ppyos.xijiuyou.com/202212/carve-h5-invite-redpack.png" alt="">
                <p>恭喜您瓜分到 <span>{{myMoney}}元</span> 奖励  <br/> 分享一下即可领取</p>
                <div @click="fenxiang"> <img src="https://ppyos.xijiuyou.com/202212/zf-icon.png" alt=""> 立即分享</div>
            </div>
        </xwpopup>

        <div class="bill" ref="bill">
            <div class="billhead">
                <img crossorigin="anonymous" :src="user.avatar?user.avatar:'https://ppyos.xijiuyou.com/202206/default_avatar.png'" alt="">
                <div>
                    <p>{{user.nickname}}</p>
                    <p>我正在玩彩贝小游戏，一起来泡泡鱼玩玩吧</p>
                </div>
            </div>
            <div class="qrcode">
                <div id="qrcode" ref="qrcode"></div>
                <p>扫码一起玩</p>
            </div>
            <img crossorigin="anonymous" class="share-bg" src="https://ppyos.xijiuyou.com/202212/carve-h5-invite-bg.png" alt="">
        </div>

        <xwpopup  :isshowpopup="fenimg" @close="closefenimg()" :hiddenClose="true">
            <div class="fenimg">
                <img :src="imgqr.imgblob" alt="" />
                <img @click="closefenimg" src="https://ppyos.xijiuyou.com/210916/close-btn.jpg" alt="">
                <div class="fenimgbottom">
                    <div @click="wechatorqq(1)">
                        <img src="https://ppyos.xijiuyou.com/202212/icon-wechat.png" alt="">
                        <p>微信分享</p>
                    </div>
                    <div @click="wechatorqq(2)">
                        <img src="https://ppyos.xijiuyou.com/202212/icon-qq.png" alt="">
                        <p>QQ分享</p>
                    </div>
                </div>
            </div>
        </xwpopup>
    </div>
</template>
<script>
import xwpopup from "../components/Popupbox.vue";
import guafen from "../api/guafen";
import html2canvas from "html2canvas";
import game from "../api/game";
import QRCode from "qrcodejs2";
import home from "../api/home";
import Chest from "../api/Chest";
import { Toast } from "vant";
export default {
  components: {
    xwpopup,
  },
  data(){
    return{
        guize:false,
        show:false,
        succ:false,
        sgid:0,
        bigicon:"",
        drawTime:"",
        issue:"",
        name:"",
        processes:[],
        fenimg:false,
        succnmoney:false,
        myScore:"",
        myTickets:0,
        totalTickets:0,
        showMaxOneTicketMoney:0,
        totalMoney:0,
        myAward:0,
        myRank:{},
        list:[],
        huojlist:[],
        enable:true,
        enableTips:"",
        myTicketsq:0,
        myMoney:0,
        consttime:true,
        newtime:0,
        succnewtime:false,
        user:{},
        shareUrl:"",
        imgqr:{},
    }
  },
  mounted(){
    this.banner();
    this.myInviteLink();
    this.reliefChest();
    this.newtime = new Date().getTime()/1000;
    console.log(this.newtime)
    if(this.newtime > 1671897599 && this.newtime < 1672761599){
        this.succnewtime = false;
    }else{
        this.succnewtime = true;
    }
  },
  methods:{
    fenxiang(){
        this.succnmoney = false;
        this.fenimg = true;
    },
    closesuccnmoney(){
        this.succnmoney = !this.succnmoney;
    },
    wechatorqq(index){
        guafen.carvedraw().then(res=>{
            if(res.code === 0){
                const time = setTimeout(()=>{
                    Toast("奖励已成功到账");
                    this.records();
                    clearTimeout(time);
                },2000)
                
            }
        })
        if(index==1){
            try {
                window.android.shareToWX(
                    0,2,"","",this.imgqr.img,1,""
                );
            } catch (e) {
                Toast("您当前app版本过低请更新app");
            }
        }else{
            try {
                window.android.shareToQQ(
                    0,2,"","",this.imgqr.img,1,""
                );
            } catch (e) {
                Toast("您当前app版本过低请更新app");
            }
        }
        this.fenimg = false;
    },
    closefenimg(){
        this.fenimg = !this.fenimg;
    },
    showoffer(){
        if(this.huojlist.find(res=> (res.status === 1))){
            // guafen.carvedraw().then(res=>{
            //     if(res.code === 0){
            //         Toast("领取成功");
            //         this.records();
            //     }
            // })
            this.succnmoney = true;
            this.myMoney = 0;
            this.huojlist.map(res=>{
                if(res.status === 1){
                    this.myMoney = this.myMoney + res.myMoney
                }
            })

        }else if(this.huojlist.find(res=> (res.status !== 1 && this.myTickets > 0))){
            Toast("请明日9点过来领奖哦"); 
        }else if(this.huojlist.find(res=> (res.status !== 1 && this.myTickets <= 0))){
            Toast("请立即开始玩游戏获得奖券吧"); 
        }else{
            this.succnmoney = true;
            this.myMoney = 0;
            this.huojlist.map(res=>{
                if(res.status === 1){
                    this.myMoney = this.myMoney + res.myMoney
                }
            })
        }
    },
    reliefChest(){//救济宝箱
        Chest.reliefChest().then(res=>{
            this.shareUrl = res.data.shareUrl;
            this.qrcode();
        })
    },
    myInviteLink(){//分享头像
        home.myInviteLink().then(res=>{
            this.user = res.data;
        })
    },
    qrcode(){
        this.$refs['qrcode'].innerHTML = ''
        new QRCode("qrcode", {
            width: 165, // 二维码宽度，单位像素
            height: 165, // 二维码高度，单位像素
            text: this.shareUrl, // 生成二维码的链接
        });
        const time = setTimeout(()=>{
            this.htmlcanvas();
            clearTimeout(time);
        },300)
    },
    async htmlcanvas(){
        const that = this;
        const bill = await html2canvas(this.$refs.bill, { useCORS: true });
        bill.toBlob(
            function (blob) {
                that.imgqr={
                    imgblob: URL.createObjectURL(blob),
                    img: bill.toDataURL("image/png"),
                };
            },
            "image/jpeg",
            0.95
        );
    },
    closesuccnewtime(){
        this.succnewtime = !this.succnewtime;
    },
    btnpk(){
        if(this.enable){
            try {
            window.android.createNewWeb(
                window.location.href.split("Carveup")[0] + "arena?sgid=" + this.sgid
            );
            } catch (e) {
            Toast("您当前app版本过低请更新app");
            }
        }else{
            Toast(this.enableTips);
        }
        // this.$router.push({
        //     name:"arena",
        //     query:{
        //         sgid:this.sgid
        //     }
        // })
        
    },
    gogame(){
      game.smartstart({gid:this.sgid}).then(res=>{
       try{
          window.android.openH5Game(res.data.link)
        }catch(err){
            console.log(err)
        }
      })
    },
    pklistRank(){
        game.pklistRank({gid:this.sgid,code:0}).then(res=>{
            this.myRank = res.myRank;
            res.list.map((_res,index)=>{
                if(index<4){
                    this.list.push(_res)
                }
            })
        })
    },
    carvedraw(id,sdate){
        const that = this;
        this.myMoney = sdate.myMoney;
        if(!that.consttime){
            return false;
        }
        this.consttime = false;
        guafen.carvedraw({gid:id,sdate:sdate.sdate}).then(res=>{
            console.log(res)
            if(res.code == 0){
                this.succnmoney = true;
                this.show = false;
                const time = setTimeout(()=>{
                    that.records();
                    this.consttime = true;
                    clearTimeout(time);
                },300)
            }
        })
    },
    records(){
        guafen.records(this.sgid).then(res=>{
            console.log(res)
            this.huojlist = res.list
        })
    },
    banner(){
        guafen.banner().then(res=>{
            if(res.data.enable){
                this.sgid = res.data.sgid;
                this.carveinfo();
                this.pklistRank();
                this.records();
            }else{
                Toast("活动已结束");
            }
        })
    },
    carveinfo(){
        guafen.carveinfo(this.sgid).then(res=>{
            this.bigicon = res.data.icon;
            this.drawTime = res.data.drawTime;
            this.issue = res.data.issue;
            this.name = res.data.name;
            this.processes = res.data.processes;
            this.myScore = res.data.myScore;
            this.myTickets= res.data.myTickets;
            this.totalTickets = res.data.totalTickets;
            this.showMaxOneTicketMoney = res.data.showMaxOneTicketMoney;
            this.totalMoney= res.data.totalMoney;
            this.myAward = res.data.myTotalAward;
            this.enable = res.data.enable;
            this.enableTips = res.data.enableTips;
            
            if(res.data.myTickets>0){
                if(localStorage.getItem("myTickets")){
                    if(localStorage.getItem("issue") == res.data.issue){
                        if(localStorage.getItem("myTickets") != res.data.myTickets){
                            this.myTicketsq = res.data.myTickets - localStorage.getItem("myTickets");
                        }
                    }else{
                        localStorage.setItem("issue",res.data.issue);
                        localStorage.setItem("myTickets",res.data.myTickets);
                        this.myTicketsq = res.data.myTickets;
                        this.succ = true;
                    }
                }else{
                    localStorage.setItem("myTickets",res.data.myTickets);
                    localStorage.setItem("issue",res.data.issue)
                    this.myTicketsq = res.data.myTickets;
                    this.succ = true;
                }
            }
            
        })
    },
    closesucc(){
        this.succ = !this.succ
    },
    closeguize(){
        this.guize = !this.guize;
    },
  }
}
</script>
<style lang="less" scoped>
.Carveup{
    width: 100%;
    min-height: 100vh;
    background: rgba(111, 205, 253, 1);
    padding-bottom: 85px;
    .Carveup-head{
        width: 100%;
        background: url("https://ppyos.xijiuyou.com/202212/carve-bg.png") no-repeat;
        background-size: 100%;
        padding-top:6px;
        padding-bottom: 51px;
        .Totalfund{
            width: calc(100% - 16px);
            height: 44px;
            background: url("https://ppyos.xijiuyou.com/202212/carve-red-w-img.png") no-repeat;
            background-size: 100%;
            margin: 7px auto 0px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding-top:6px;
            p{
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                img{
                    width: 17px;
                    height: 17px;
                    margin-right: 2px;
                    margin-top: -1px;
                }
                span:nth-child(3){
                    font-size: 14px;
                    font-family: DINOffcPro-Bold, DINOffcPro;
                    font-weight: bold;
                    color: #FFF1B2;
                    margin-top:3px;
                }
            }
        }
        .Carveup-head-time{
            width: 350px;
            height: 87px;
            margin: 0 auto;
            background: url("https://ppyos.xijiuyou.com/202212/carve-banner-img.png") no-repeat;
            background-size: 100%;
            position: relative; 
            span{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                position: absolute;
                bottom: 11px;
                left: 85px;
            }
        }
        .Carveup-head-logo{
            margin: 7px 0;
            padding-left: 14px;
            display: flex;
            align-items: center;
            img{
                width: 58px;
                height: 58px;
                border-radius: 7px; 
                margin-right: 10px;
            }
            div{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                p:nth-child(1){
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    margin-bottom: 5px;
                }
            }
        }
    }
    .Strategy{
        background: rgba(255,255,255,0.7);
        box-shadow: inset 0px 1px 0px 0px #FFFFFF;
        border-radius: 15px;
        backdrop-filter: blur(10px);
        width: calc(100% - 20px);
        margin: -40px auto 0;
        padding-top: 14px;
        .Strategy-content{
            width: 100%;
            background: linear-gradient(180deg, #FFFAF6 0%, #FFCDD8 100%);
            box-shadow: inset 0px 1px 0px 0px #FFFFFF, inset 0px -1px 6px 0px #FFFFFF;
            border-radius: 20px;
            padding: 8px 9px 14px;
            position: relative;
            text-align: center;
            .draw-btn{
                width: calc(100% - 110px);
                margin: 0 auto;
                margin-top: 16px;
            }
            .irecords{
                position:absolute;
                top:9px;
                right: 9px;
                width: 53px;
                height: 34px;
            }
            .Strategy-content-head{
                // display: flex;
                // justify-content: space-between;
                text-align: center;
                // p:nth-child(1){
                //     width: 72px;
                //     height: 24px;
                //     background: linear-gradient(270deg, rgba(255, 113, 140, 0.24) 0%, rgba(255, 149, 179, 0.24) 100%);
                //     border-radius: 12px;
                //     font-size: 12px;
                //     font-family: PingFangSC-Regular, PingFang SC;
                //     font-weight: 400;
                //     color: #FF305E;
                //     text-align: center;
                //     line-height: 24px;
                // }
                p{
                    font-size: 16px;
                    font-family: AlimamaShuHeiTi-Bold, AlimamaShuHeiTi;
                    font-weight: bold;
                    color: #700000;
                }
                // p:nth-child(3){
                //     width: 72px;
                //     height: 24px;
                // }
            }
            .Strategy-content-quan{
                width: 323px;
                height: 59px;
                background: url("https://ppyos.xijiuyou.com/202212/carve-process-img.png") no-repeat;
                background-size: 100%;
                display: flex;
                justify-content: space-between;
                margin: 0 auto;
                padding-left: 22px;
                padding-right: 36px;
                padding-top: 5px;
                align-items: center;
                div{
                    text-align: center;
                    p:nth-child(1){
                        font-size: 12px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FF305E;
                    }
                    p:nth-child(2){
                        font-size: 12px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FF305E;
                        margin-top: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        span:nth-child(1){
                            font-size: 18px;
                            font-family: DINOffcPro-Black, DINOffcPro;
                            font-weight: 900;
                            color: #FF305E;
                        }
                        span:nth-child(2){
                            margin-top: -2px;
                        }
                    }
                }
                div:nth-child(2){
                    p:nth-child(1){
                        color: #FFF3DC;
                        display: flex;
                        img{
                            width:17px;
                            height: 17px;
                            margin-left: 2px;
                            margin-top: -2px;
                        }
                    }
                    p:nth-child(2){
                        color: #FFF3DC;
                        span{
                            color: #FFF3DC;
                        }
                    }
                }
            }
            .Strategy-content-money{
                font-size: 22px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FF2727;
                text-align: center;
                margin-bottom: 2px;
                margin-top: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    position: relative;
                    font-size: 45px;
                    font-family: DINOffcPro-Black, DINOffcPro;
                    font-weight: 900;
                    color: #FF0E43;
                    letter-spacing: 4px;
                    z-index: 1;
                    background: linear-gradient(180deg, rgba(255, 141, 98, 0.7) 0%, rgba(255, 0, 70, 1) 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
        .Strategy-head{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            img{
                width: 55px;
                height: 14px;
                margin-right: 3px;
            }
            div{
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                margin: 0 2px;
                div{
                   margin: 0 4px 0 0;
                   background: url("https://ppyos.xijiuyou.com/202212/empty-msg-icon.png") no-repeat;
                   background-size: 100%; 
                   width: 26px;
                   height: 20px;
                   padding-left: 12px;
                   font-size: 14px;
                    font-family: AlibabaPuHuiTiB;
                    color: #6FCCFD;
                    line-height: 20px;
                }
            }
        }
    }
    .Ranking{
        width: calc(100% - 20px);
        background: #FFFFFF;
        border-radius: 20px;
        margin: 10px auto 0;
        text-align: center;
        padding: 8px 4px;
        .Ranking-title{
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
        }
        .Ranking-titles{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            margin-top: 58px;
            margin-bottom: 12px;
        }
        .progress{
            width: calc(100% - 27px);
            height: 7px;
            background: #FFE9D7;
            border-radius: 5px;
            margin: 12px auto 0;
            display: flex;
            justify-content: space-around;
            position: relative;
            .jindu{
                position: absolute;
                width: 0;
                height: 100%;
                left: 0;
                border-radius: 5px;
                background: linear-gradient(270deg, #FF2850 0%, #FE7F55 100%);
            }
            div{
                width: 5px;
                height: 5px;
                background: #FFB87E; 
                border-radius: 50%;
                position: relative;
                margin-top: 1px;
                z-index: 1;
                p{
                    position: absolute;
                }
                p:nth-child(1){
                    font-size: 12px;
                    font-family: DINOffcPro-Bold, DINOffcPro;
                    font-weight: bold;
                    color: #333333;
                    bottom: -20px;
                    width: 100px;
                    left: -45px;
                }
                p:nth-child(2){
                    font-size: 12px;
                    font-family: DINOffcPro-Bold, DINOffcPro;
                    font-weight: bold;
                    color: #333333;
                    bottom: -40px;
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FF2727;
                    display: flex;
                    width: 100px;
                    left: -45px;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 21px;
                        height: 16px;
                        margin-left: 2px;
                    }
                }
            }
        }
    }
    .listmy{
        display: flex;
        justify-content: space-between;
        height: 68px;
        align-items: center;
        padding: 0 10px;
        div:nth-child(1){
            display: flex;
            align-items: center;
            width: 150px;
            white-space: nowrap;
            span:nth-child(1){
                display: inline-block;
                width: 20px;
                height: 20px;
                font-size: 16px;
                font-family: DINOffcPro-Medi, DINOffcPro;
                font-weight: normal;
                color: #444444;
                text-align: center;
                line-height: 20px;
                border-radius: 50%;
            }
            img:nth-child(1){
                width: 20px;
                height: 26px;
                margin: 0;
            }
            img{
                width: 32px;
                height: 32px;
                margin: 0 11px 0 13px;
                border-radius: 50%;
            }
            span{
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #444444;
            }
            span:nth-child(3){
                width: 60px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;
            }
        }
        div{
            font-size: 16px;
            font-family: DINOffcPro-Medi, DINOffcPro;
            font-weight: normal;
            color: #444444;
        }
        div:nth-child(2){
            width: 50px;
            text-align: left;
            white-space: nowrap;
        }
        div:nth-child(3){
            width: 60px;
            text-align: right;
            white-space: nowrap;
        }
    }
    .quanbtn{
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 68px;
        left: 0;
        background: #FFFFFF;
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.14);
        padding-top: 10px;
        z-index: 10;
        div{
            width: 352px;
            height: 48px;
            background: url("https://ppyos.xijiuyou.com/202212/carve-red-img.png") no-repeat;
            background-size: 100%;
            margin: 0 auto;
            text-align: center;
            line-height: 48px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }
    }
    .btnpk{
            width: calc(100% - 28px);
            height: 36px;
            background: linear-gradient(180deg, #F6F6F6 0%, #FFFFFF 100%);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            margin: 0 auto 2px;
            img{
                margin-left: 5px;
                width: 6px;
                height: 8px;
            }
        }
        .jilu{
            width: 64px;
            height: 24px;
            background: #FFFFFF;
            border-radius: 13px 0px 0px 13px;
            position: absolute;
            right: 0;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            top: 95px;
            text-align: center;
            line-height: 24px;
        }
        .guize{
            position: absolute;
            right: 0;
            top: 130px;
            width: 64px;
            height: 24px;
            background: #FFFFFF;
            border-radius: 13px 0px 0px 13px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            text-align: center;
            line-height: 24px;
        }
        .guizes{
            width: 305px;
            background: #FFFFFF;
            border-radius: 20px;
            margin-top: 100px;
            padding: 24px 28px;
            max-height: 400px;
            overflow-x: auto;
            .gongl{
                margin-top: 20px;
                text-align: left;
                padding-left: 10px;
            }
            div{
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: bold;
                color: #444444;
                margin-bottom: 10px;
            }
            p{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #444444;
                text-align: left;
                display: flex;
                line-height: 30px;
                span{
                    display: block;
                }
            }
        }
}
.van-popup{
    background: rgba(246, 246, 246, 1);
}
.showcontent{
    padding: 0px 12px 12px;
    max-height: 500px;
    .wulist{
        text-align: center;
        img{
            width: 123px;
            height: 101px;
            margin-top: 32px;
        }
        p{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #888888;
            margin-top: 10px;
        }
    }
    .showcontent-title{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        text-align: center;
        margin-bottom: 13px;
    }
    .showcontentlist{
        width: 100%;
        height: 102px;
        background: #ffffff;
        border-radius: 15px;
        margin-bottom: 10px;
        .showcontentlisttitle{
            width: 144px;
            height: 24px;
            background: linear-gradient(180deg, #FFE7D8 0%, #FFCDD8 100%);
            box-shadow: inset 0px -1px 6px 0px rgba(255,255,255,0.7);
            border-radius: 0px 0px 10px 10px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            text-align: center;
            line-height: 24px;
            color: #FF2727;
            margin: 0 auto 18px;
        }
        .showcontentlistcontent{
            display: flex;
            justify-content: space-around;
            div{
                width: 100%;
                text-align: center;
                p:nth-child(1){
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #444444; 
                }
                p:nth-child(2){
                    font-size: 18px;
                    font-family: DINOffcPro-Medi, DINOffcPro;
                    font-weight: normal;
                    color: #444444; 
                    margin-top: 9px;
                    span{
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #444444;
                    }
                }
            }
            div:nth-child(3){
                .yuan{
                    color: rgba(255, 39, 39, 1);
                    span{
                        color: rgba(255, 39, 39, 1);
                    }
                }
                .cany{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #BBBBBB;
                }
                .weikai{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #444444;
                }
                .lingq{
                    width: 67px;
                    height: 24px;
                    background: linear-gradient(270deg, #FF5675 0%, #FE7F55 100%);
                    border-radius: 12px; 
                    font-size: 11px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 24px;
                    margin: 9px auto 0;
                }
            }
        }
    }
}
.succ{
    width: 295px;
    background: #FFFFFF;
    border-radius: 20px;
    margin-top: 150px;
    padding-bottom: 30px;
    .title-content{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        text-align: center;
        margin-top: 27px;
        margin-bottom: 10px;
    }
    .title-btn{
        width: 172px;
        height: 48px;
        background: url("https://ppyos.xijiuyou.com/202212/red-btn-img.png") no-repeat;
        background-size: 100%;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin: 0 auto;
        line-height: 48px;
    }
    .title{
        width: 295px;
        height: 199px;
        background: url("https://ppyos.xijiuyou.com/202212/open-success-img.png") no-repeat;
        background-size: 100%;
        margin-top: -54px;
        position: relative;
        p:nth-child(1){
            position: absolute;
            bottom: 43px;
            width: 89px;
            height: 34px;
            background: #FFFFFF;
            border-radius: 17px;
            font-size: 28px;
            font-family: DINOffcPro-Black, DINOffcPro;
            font-weight: 900;
            color: #FF4175;
            left: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FF4175; 
            }
        }
        p:nth-child(2){
            left: 28px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            bottom: 20px;
            position: absolute;
        }
    }
}
.van-action-sheet__header{
    line-height: 30px;
    padding-top: 10px;
}
.van-icon{
    padding-top: 10px;
}
.succnewtime{
    width: calc(100% - 70px);
    margin: 100px auto 0;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 19px 0 30px;
    img{
        width: calc(100% - 66px);
    }
    p{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444; 
        margin: 20px 0 24px;
        span{
            color: rgba(255, 39, 39, 1);
        }
    }
    div{
        width: 172px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin: 0 auto;
        background: url("https://ppyos.xijiuyou.com/202212/ppy-red-btn-img.png") no-repeat;
        background-size: 100%;
    }
}



.bill{
        width: 375px;
        height: 613px;
        position: fixed;
        left: -10000px;
        top: 0px;
        padding-top: 11px;
        .share-bg{
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
        .billhead{
            position: relative;
            z-index: 1;
            width: calc(100% - 18px);
            height: 98px;
            background: #ffffff;
            border-radius: 10px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            padding: 23px 14px;
            img{
                width: 52px;
                height: 52px;
                margin-right: 10px;
                border-radius: 50%;
            }
            div{
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #444444;
                text-align: left;
                p:nth-child(2){
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #444444;
                }
            }
        }
        .qrcode{
            position: relative;
            z-index: 1;
            margin: 100px auto 0;
            text-align: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
        }
    }
    #qrcode{
        width: 165px;
        height: 165px;
        margin: 0 auto 16px;
    }
    .fenimg{
        width: 100vw;
        height: 100vh;
        text-align: center;
        padding-top: 20px;
        position: relative;
        img:nth-child(1){
            width: calc(100% - 136px);
            display: block;
            margin: 0 auto;
        }
        img:nth-child(2){
            width: 26px;
            height: 26px;
            margin-top: 26px;
        }
        .fenimgbottom{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 118px;
            background: #FFFFFF;
            display: flex;
            justify-content: space-around;
            padding-top:20px;
            div{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #444444;
                img{
                    width: 54px;
                    height: 54px;
                }
            }
        }
    }
    .succnmoney{
        width: calc(100% - 70px);
        margin: 100px auto 0;
        background: #ffffff;
        border-radius: 20px;
        padding-bottom: 30px;
        img:nth-child(1){
            width: calc(100% - 16px);
            margin: -48px auto 0;
        }
        p{
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #444444;
            margin:28px 0 20px 0;
            span{
                color: rgba(255, 65, 117, 1);
            }
        }
        div{
            width: 172px;
            height: 48px;
            background: url("https://ppyos.xijiuyou.com/202212/carve-h5-invite-btn.png") no-repeat;
            background-size: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            margin: 0 auto;
            img:nth-child(1){
                width: 16px;
                height: 16px;
                margin: 0;
            }
        }
    }
</style>