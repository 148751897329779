import axios from "axios";
// import qs from'qs';
const guafen = {
    banner() {
    return axios.get("/api/ppy/gamecenter/smart/carve/banner");
  },
  carveinfo(gid) {
    return axios.get("/api/ppy/gamecenter/smart/carve/info",{
        params:{
            gid
        }
    });
  },
  records(gid) {
    return axios.get("/api/ppy/gamecenter/smart/carve/records",{
        params:{
            gid
        }
    });
  },
  carvedraw(data) {
    return axios.get("/api/ppy/gamecenter/smart/carve/draw",{
        params:data
    });
  },
};
export default guafen;
